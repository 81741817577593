import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {Link} from 'react-router-dom';
import {checkIfRoomIsClosed, checkIfRoomIsOpened, checkIfRoomOpensSoon} from 'helpers/rooms-helper';
import {getFormattedDate} from 'helpers/date-helper';
import {uiTexts} from 'data/ui-texts';
import {citiesData} from 'data/cities-data';
import LanguageIcons from 'components/ui/language-icons/language-icons';
import './rooms.scss';

const Rooms = ({type, languageId, deviceInfo, rooms, userData}) => {

	/* Get completed rooms */
	const completedRooms = (userData && userData.completedRooms ? userData.completedRooms : []);


	/* Toggle room completed */
	const toggleRoomCompleted = (venueId, roomId) => {
		if (userData && userData.id) {
			const newCompletedRooms = JSON.parse(JSON.stringify(completedRooms));
			const roomIndex = newCompletedRooms.findIndex((r) => {
				return (r.roomId === roomId && r.venueId === venueId);
			});
			if (roomIndex >= 0) {
				newCompletedRooms.splice(roomIndex, 1);
			} else {
				newCompletedRooms.push({venueId, roomId});
			}
			const db = firebase.firestore();
			db.collection('users').doc(userData.id).update({completedRooms: newCompletedRooms}).then(() => {
						
			}).catch((error) => {
				console.error(error);
			});
		}
		
	};

	return (
		<div className={'Rooms ' + deviceInfo.orientation + (type ? ' ' + type : '')}>

			{/* Loop over rooms */}			
			{rooms.map((room) => {
				/* Get room image */
				let bgImg = require('../../assets/images/default.jpg');
				try {
					bgImg = require('../../assets/images/rooms/' + room.venueData.id + '_' + room.id + '.jpg');
				} catch (e) {
					console.error('Could not find image: ' + room.venueData.id + '_' + room.id + '.jpg');
				}

				/* Get venue name */
				let venueName = room.venueData.name;

				/* Get venue website */
				let website = null;
				if (room.venueData.website) {
					website = room.venueData.website
						.replace('https://www.', '')
						.replace('http://www.', '')
						.replace('http://', '')
						.replace('https://', '')
						.replace('www.', '');	
				}

				/* Add city name to venue name if it is not already in the name */
				if (room.locations && room.locations.length > 1) {
					venueName += ', ' + uiTexts.multipleCities[languageId];
				} else if (room.locations && room.locations.length === 1) {
					const locationData = 
						room.venueData.locations.find((l) => {return l.id === room.locations[0].locationId;});
					if (locationData) {
						const cityData = citiesData.find((c) => {return c.id === locationData.cityId;});
						const cityName = cityData ? cityData.name[languageId] : null;
						if (cityName && venueName.toLowerCase().indexOf(cityName.toLowerCase()) < 0) {
							venueName += ', ' + cityName;
						}
					}
				}
				
				/* Check if room is closed */
				const roomIsClosed = checkIfRoomIsClosed(room);

				/* Check if room is open */
				const roomIsOpen = checkIfRoomIsOpened(room);

				/* Check if room will open soon */
				const roomOpensSoon = (!roomIsOpen && checkIfRoomOpensSoon(room));

				/* Check if room is completed */
				const isCompleted = (completedRooms.some((r) => {
					return (r.venueId === room.venueData.id && r.roomId === room.id);
				}));

				/* Get link to venue */
				let linkTo = '/' + room.venueData.id;

				return (
					<div 
						key={room.venueData.id + '-' + room.id} 
						className="Rooms-room" 
						style={{backgroundImage: 'url(' + bgImg + ')'}}
					>
						{roomIsClosed && 
							<div className="Rooms-roomStatus closed"><span>{uiTexts.closed[languageId]}</span></div>}
						{!roomIsOpen &&
							<div className="Rooms-roomStatus future">
								<span>{uiTexts.roomOnTheWay[languageId]}</span>
							</div>}
						<div className="Rooms-roomInfo">
							<div className="Rooms-roomName">{room.name[languageId]}</div>
							{type !== 'venue-popup' && <Link className="Rooms-venueName" to={linkTo}>{venueName}</Link>}
							{type === 'venue-popup' && 
								<div className="Rooms-numberOfRooms">
									{room.rooms} {room.rooms > 1 ? uiTexts.rooms[languageId] : uiTexts.room[languageId]}
								</div>}
							<div className="Rooms-extraInfo">
								<div>
									<span>{uiTexts.teamSize[languageId]}:</span> 
									{' ' + (room.teamSizeMin === room.teamSizeMax 
										? room.teamSizeMin 
										: room.teamSizeMin + ' - ' + room.teamSizeMax)} {uiTexts.players[languageId]
									}
								</div>
								<div>
									<span>{uiTexts.timeLimit[languageId]}:</span>
									{' ' + room.minutes} {uiTexts.minutes[languageId]}
								</div>
								{room.languages && <div>
									<span>{uiTexts.languages[languageId]}:</span> 
									<LanguageIcons languageId={languageId} languageIds={room.languages} />
								</div>}
								{room.minimumAge && 
									<div><span>{uiTexts.age[languageId]}:</span> {room.minimumAge + '+'}</div>}
								{(type !== 'venue-popup' && room.venueData.website) && <div className="Rooms-website">
									<span>
										{uiTexts.website[languageId]}:</span> <a 
										href={room.venueData.website}
										target="_blank"
										rel="noopener noreferrer"	
									>{website}</a>
								</div>}
								{(type === 'venue-popup' && roomIsOpen && room.opened && room.opened.length === 8) && 
									<div>
										<span>{uiTexts.opened[languageId]}: </span>
										{getFormattedDate(languageId, room.opened)}
									</div>}

								{(type === 'venue-popup' && roomOpensSoon && room.opened && room.opened.length === 8) &&
									<div>
										<span>{uiTexts.opens[languageId]}: </span>
										{getFormattedDate(languageId, room.opened)}
									</div>}
								{(type === 'venue-popup' && roomIsClosed && room.closed && room.closed.length === 8) && 
									<div>
										<span>{uiTexts.closed2[languageId]}: </span>
										{getFormattedDate(languageId, room.closed)}
									</div>}
							</div>
						</div>
						{userData && <div 
							className={'Rooms-completedStatus' + (isCompleted ? ' completed' : '')} 
							onClick={() => {toggleRoomCompleted(room.venueData.id, room.id);}}
						/>}
					</div>
				);
			})}
		</div>
	);
};


Rooms.propTypes = {
	type: PropTypes.string,
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	rooms: PropTypes.array.isRequired,
	userData: PropTypes.object
};

export default Rooms;