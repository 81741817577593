const citiesData = [
		{
			id: 'aalborg',
			name: {
				en: 'Aalborg',
				da: 'Aalborg'
			},
			regionId: 'jutland'
		},
		{
			id: 'aarhus',
			regionId: 'jutland',
			name: {
				en: 'Aarhus',
				da: 'Aarhus'
			}
		},
		{
			id: 'albertslund',
			regionId: 'sealand',
			name: {
				da: 'Albertslund',
				en: 'Albertslund'
			}
		},
		{
			id: 'ballerup',
			regionId: 'sealand',
			name: {
				da: 'Ballerup',
				en: 'Ballerup'
			}
		},
		{
			id: 'birkeroed',
			name: {
				da: 'Birkerød',
				en: 'Birkerød'
			},
			regionId: 'sealand'
		},
		{
			id: 'copenhagen',
			name: {
				da: 'København',
				en: 'Copenhagen'
			},
			regionId: 'sealand'
		},
		{
			id: 'esbjerg',
			name: {
				en: 'Esbjerg',
				da: 'Esbjerg'
			},
			regionId: 'jutland'
		},
		{
			id: 'frederikshavn',
			regionId: 'jutland',
			name: {
				en: 'Frederikshavn',
				da: 'Frederikshavn'
			}
		},
		{
			id: 'grenaa',
			regionId: 'jutland',
			name: {
				en: 'Grenaa',
				da: 'Grenaa'
			}
		},
		{
			id: 'greve',
			regionId: 'sealand',
			name: {
				en: 'Greve',
				da: 'Greve'
			}
		},
		{
			id: 'haderslev',
			regionId: 'jutland',
			name: {
				en: 'Haderslev',
				da: 'Haderslev'
			}
		},
		{
			id: 'herning',
			regionId: 'jutland',
			name: {
				en: 'Herning',
				da: 'Herning'
			}
		},
		{
			id: 'hilleroed',
			regionId: 'sealand',
			name: {
				en: 'Hillerød',
				da: 'Hillerød'
			}
		},
		{
			id: 'hjorring',
			regionId: 'jutland',
			name: {
				en: 'Hjørring',
				da: 'Hjørring',
			}
		},
		{
			id: 'holbaek',
			regionId: 'sealand',
			name: {
				da: 'Holbæk',
				en: 'Holbæk'
			}
		},
		{
			id: 'holstebro',
			name: {
				en: 'Holstebro',
				da: 'Holstebro'
			},
			regionId: 'jutland'
		},
		{
			id: 'horsens',
			name: {
				en: 'Horsens',
				da: 'Horsens',
				regionId: 'jutland'
			}
		},
		{
			id: 'koege',
			regionId: 'sealand',
			name: {
				da: 'Køge',
				en: 'Køge'
			}
		},
		{
			id: 'kolding',
			regionId: 'jutland',
			name: {
				en: 'Kolding',
				da: 'Kolding'
			}
		},
		{
			id: 'lyngby',
			regionId: 'sealand',
			name: {
				en: 'Lyngby',
				da: 'Lyngby'
			}
		},
		{
			id: 'naestved',
			name: {
				da: 'Næstved',
				en: 'Næstved'
			},
			regionId: 'sealand'
		},
		{
			id: 'nykoebing-falster',
			regionId: 'funen',
			name: {
				en: 'Nykøbing Falster',
				da: 'Nykøbing Falster'
			}
		},
		{
			id: 'odder',
			regionId: 'jutland',
			name: {
				da: 'Odder',
				en: 'Odder'
			}
		},
		{
			id: 'odense',
			name: {
				en: 'Odense',
				da: 'Odense'
			},
			regionId: 'funen'
		},
		{
			id: 'padborg',
			name: {
				en: 'Padborg',
				da: 'Padborg'
			},
			regionId: 'jutland'
		},
		{
			id: 'randers',
			regionId: 'jutland',
			name: {
				en: 'Randers',
				da: 'Randers'
			}
		},
		{
			id: 'roedovre',
			regionId: 'sealand',
			name: {
				en: 'Rødovre',
				da: 'Rødovre'
			}
		},
		{
			id: 'roedvig',
			regionId: 'sealand',
			name: {
				en: 'Rødvig',
				da: 'Rødvig'
			}
		},
		{
			id: 'roskilde',
			name: {
				da: 'Roskilde',
				en: 'Roskilde'
			},
			regionId: 'sealand'
		},
		{
			id: 'silkeborg',
			regionId: 'jutland',
			name: {
				da: 'Silkeborg',
				en: 'Silkeborg'
			}
		},
		{
			id: 'skagen',
			name: {
				en: 'Skagen',
				da: 'Skagen'
			},
			regionId: 'jutland'
		},
		{
			id: 'skanderborg',
			name: {
				en: 'Skanderborg',
				da: 'Skanderborg'
			},
			regionId: 'jutland'
		},
		{
			id: 'skive',
			regionId: 'jutland',
			name: {
				en: 'Skive',
				da: 'Skive'
			}
		},
		{
			id: 'slagelse',
			regionId: 'sealand',
			name: {
				en: 'Slagelse',
				da: 'Slagelse'
			}
		},
		{
			id: 'soenderborg',
			regionId: 'jutland',
			name: {
				en: 'Sønderborg',
				da: 'Sønderborg'
			}
		},
		{
			id: 'svendborg',
			regionId: 'funen',
			name: {
				en: 'Svendborg',
				da: 'Svendborg'
			}
		},
		{
			id: 'tarm',
			name: {
				da: 'Tarm',
				en: 'Tarm'
			},
			regionId: 'jutland'
		},
		{
			id: 'tilst',
			regionId: 'jutland',
			name: {
				en: 'Tilst',
				da: 'Tilst'
			}
		},
		{
			id: 'ulfborg',
			name: {
				da: 'Ulfborg',
				en: 'Ulfborg'
			},
			regionId: 'jutland'
		},
		{
			id: 'varde',
			regionId: 'jutland',
			name: {
				da: 'Varde',
				en: 'Varde'
			}
		},
		{
			id: 'vejle',
			regionId: 'jutland',
			name: {
				da: 'Vejle',
				en: 'Vejle'
			}
		},
		{
			id: 'viborg',
			regionId: 'jutland',
			name: {
				da: 'Viborg',
				en: 'Viborg'
			}
		}
	];

	export {citiesData};