import {getCurrentDate} from './date-helper';
import {getFilteredRooms, getSortedRooms} from 'helpers/filter-helpers';
import {citiesData} from 'data/cities-data';
import {venuesAndRooms} from 'data/venues-and-rooms-data';



const getFilteredAndSortedRooms = (
	showClosed, showFuture, hideCompleted, languageId, regionId, cityId, excludedLanguageIds, sortBy, sortDir, userData
) => {
	/* Get all rooms */
	const rooms = [];
	venuesAndRooms.forEach((venue) => {
		venue.rooms.forEach((room) => {
			const roomData = JSON.parse(JSON.stringify(room));
			roomData.venueData = JSON.parse(JSON.stringify(venue));
			rooms.push(roomData);
		});
	});

	/* Filter rooms */
	const filteredRooms = getFilteredRooms(
		rooms, 
		showClosed, 
		showFuture,
		hideCompleted,
		regionId,
		cityId,
		excludedLanguageIds,
		userData
	);

	/* Sort rooms */
	const sortedRooms = getSortedRooms(filteredRooms, languageId, sortBy, sortDir);


	return sortedRooms;
};


/**
 * Check if room is opened 
 */
const checkIfRoomIsOpened = (room) => {
	/* Get current date */
	const dateStr = getCurrentDate();

	let isOpened = false;

	if (room.hasOwnProperty('opened')) {
		isOpened = (room.opened && parseInt(room.opened) <= parseInt(dateStr));
	} else {
		/* Check if one or more locations are opened */
		isOpened = room.locations.some((location) => {
			return (location.opened && parseInt(location.opened) <= parseInt(dateStr));
		});
	}

	return isOpened;
};

/**
 * Check if room opens soon
 */
const checkIfRoomOpensSoon = (room) => {
	let opensSoon = false;

	if (room.hasOwnProperty('opened')) {
		if (room.opened && parseInt(room.opened) && room.opened !== '99999999') {
			opensSoon = true;
		}
	} else {
	/* Check if one or more locations are opened */
		opensSoon = room.locations.some((location) => {
			return (location.opened && parseInt(location.opened) && location.opened !== '99999999');
		});
	}

	return opensSoon;
	

};

/**
 * Check if room is closed
 * @param {object} room 
 * @returns 
 */
const checkIfRoomIsClosed = (room) => {
	/* Get current date */
	const dateStr = getCurrentDate();

	let isClosed = false;

	/* Get closed locations */
	if (room.hasOwnProperty('closed')) {
		if (
			room.closed === true || 
			(room.closed && room.closed.length === 8 && parseInt(room.closed) <= parseInt(dateStr))
		) {
			isClosed = true;
		}
	} else {
		const closedLocations = room.locations.filter((location) => {
			return (
				location.closed === true || 
				(location.closed && location.closed.length === 8 && parseInt(location.closed) <= parseInt(dateStr))
			);
		});
		isClosed = (closedLocations.length === room.locations.length);
	}

	 

	return isClosed;
};


/**
 * Check if room is in city
 * @param {object} room 
 * @param {string} cityId 
 * @returns 
 */
const checkIfRoomIsInCity = (room, cityId) => {
	return room.locations.some((location) => {
		return (room.venueData.locations.some((l) => {
			return (
				l.id === location.locationId &&
				l.cityId === cityId
			);
		}));
	});
};

/**
 * Check if room is in region
 * @param {object} room 
 * @param {string} regionId 
 * @returns 
 */
const checkIfRoomIsInRegion = (room, regionId) => {
	return room.locations.some((location) => {
		const locationData = room.venueData.locations.find((l) => {
			return (l.id === location.locationId);
		});
		const cityData = (locationData ? citiesData.find((city) => {return city.id === locationData.cityId;}) : null);
		return (cityData && cityData.regionId === regionId);
	});
};

const getOldestOpenedDate = (room) => {
	let oldestOpenedDate = '99999999';
	room.locations.forEach((location) => {
		if (location.opened && parseInt(location.opened) < parseInt(oldestOpenedDate)) {
			oldestOpenedDate = location.opened;
		}
	});
	return oldestOpenedDate;	
};

const getNewestOpenedDate = (room) => {
	let newestOpenedDate = '00000000';
	room.locations.forEach((location) => {
		if (location.opened && parseInt(location.opened) > parseInt(newestOpenedDate)) {
			newestOpenedDate = location.opened;
		}
	});
	return newestOpenedDate;	
};

export {
	getFilteredAndSortedRooms,
	checkIfRoomIsOpened,
	checkIfRoomOpensSoon,
	checkIfRoomIsClosed,
	checkIfRoomIsInCity,
	checkIfRoomIsInRegion,
	getOldestOpenedDate,
	getNewestOpenedDate
};