const venueTemplate = {
	id: '',
	name: '',
	website: ['']
};

const locationTemplate = {
	id: '',
	cityId: '',
	opened: '',
	closed: false,
	address: '',
	coordinates: {lng: '', lat: ''},
	website: null
};

const roomTemplate = {
	id: '',
	name: {
		da: '',
		en: ''
	},
	languages: ['da'],
	minutes: 60,
	teamSizeMin: 2,
	teamSizeMax: 6,
	minimumAge: null,
	minimumAgeNoAdult: null,
	difficulty: null,
	tagIds: [],
	description: null,
	isRecommended: false,
	locations: []
};

export {
	venueTemplate,
	locationTemplate,
	roomTemplate
};